<template>
  <v-container fluid>
    <div class="d-flex fix-header">
      <h3 class="page-title">一番賞公告管理</h3>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        @click="saveAll"
        :disabled="uploadImages.every((item) => !item)"
      >
        全部儲存
      </v-btn>
    </div>
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="4"
        v-for="(item, index) in banner"
        :key="item._id"
      >
        <div class="pa-4">
          <div class="d-flex justify-space-between mb-4">
            <h3>{{ index + 1 }}</h3>
            <div>
              <v-btn
                v-if="uploadImages[index]"
                @click="$set(uploadImages, index, null)"
                color="error"
              >
                移除
              </v-btn>
              <v-btn
                v-else-if="item.imageUrl"
                color="error"
                dense
                hide-details
                @click="remove(item)"
              >
                移除
              </v-btn>
              <v-btn v-else disabled>移除</v-btn>
            </div>
          </div>
          <div>
            <!-- <h4 class="mb-3">當前圖片</h4> -->
            <div class="preview-image">
              <LocalImagePreview
                v-if="uploadImages[index]"
                :imageFile="uploadImages[index]"
                class="preview-image"
              />
              <img v-else-if="item.imageUrl" :src="item.imageUrl" alt="" />
              <div v-else class="preview-image__placeholder"></div>
            </div>
          </div>
        </div>
        <div class="pa-4">
          <!-- <h4 class="mb-3">更新圖片</h4> -->
          <v-file-input
            v-model="uploadImages[index]"
            accept="image/png, image/jpeg, image/webp"
            label="更新內容圖"
            outlined
            dense
            hide-details
            @change="(e) => getLocalImageFile(e, index)"
            class="mb-4"
          ></v-file-input>
          <!-- <div v-if="uploadImagesUrl[index]" class="preview-image">
            <img :src="uploadImagesUrl[index]" />
          </div> -->
        </div>
        <v-divider class="my-3"></v-divider>
      </v-col>
    </v-row>
    <v-dialog>
      <v-card>
        <v-card-title></v-card-title>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "lodash";
import LocalImagePreview from "@/components/share/LocalImagePreview.vue";

export default {
  name: "Banner",
  props: [],
  components: { LocalImagePreview },
  data: () => ({
    hasImage: false,
    image: null,
    banner: [],
    uploadImages: [],
    uploadImagesUrl: [],
    bannerMap: {},
  }),
  computed: {},
  methods: {
    async load() {
      await this.getBanner();
    },
    async getBanner() {
      const { data } = await this.axios.get("/banner");
      this.banner = data;
    },
    getLocalImageFile(event, index) {
      this.uploadImages[index] = event;
      this.uploadImagesUrl[index] = event ? URL.createObjectURL(event) : null;
    },
    async saveAll() {
      const idMap = this.uploadImages.reduce((pre, file, index) => {
        if (!file) return pre;
        return [...pre, this.banner[index]._id];
      }, []);

      try {
        this.$vloading.show();
        const imageUrls = await this.getImageUrls(this.uploadImages);
        const process = imageUrls.map((url, index) =>
          this.axios.put(`/banner/${idMap[index]}`, {
            imageUrl: url,
          })
        );
        await Promise.all(process);
      } catch (e) {
        console.log(e);
        this.$toast.error("儲存失敗");

        if (e.response) {
          const info = JSON.stringify(e.response);
          this.$toast.error(info);
        } else {
          this.$toast.error(e);
        }
      } finally {
        this.uploadImages = this.uploadImages.map((file) => null);
        this.uploadImagesUrl = this.uploadImages.map((file) => null);
        await this.load();
        this.$vloading.hide();
      }
    },
    async getImageUrls(files) {
      let formData = new FormData();
      files.forEach((file) => {
        formData.append(`file`, file);
      });

      const { data } = await this.axios.post(
        `/image/upload-multiple-image`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // 設置 Content-Type 為 multipart/form-data
          },
        }
      );
      return data.imageUrls;
    },
    async remove(item) {
      const confirm = await this.$dialog.confirm({
        text: `是否移除圖片？`,
      });
      if (!confirm) return;

      this.$vloading.show();
      await this.axios.put(`/banner/${item._id}`, {
        imageUrl: "",
      });
      await this.load();
      this.$vloading.hide();
    },
  },
  async created() {
    await this.load();
  },
  async beforeRouteLeave(to, from, next) {
    if (!this.uploadImagesUrl.some((item) => !!item)) {
      return next();
    }

    const confirm = await this.$dialog.confirm({
      text: `有尚未儲存的變更，是否確定離開？`,
    });
    if (confirm) return next();
  },
};
</script>

<style lang="scss" scoped>
h3 {
  width: fit-content;
  display: inline-block;
}

.fix-header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 9;
  padding: 15px 0;
}

.preview-image {
  position: relative;
  width: 100%;
  max-width: 500px;
  height: 350px;
  background-color: #e4e4e4;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__placeholder {
    &::after {
      content: "無";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
