<template>
  <v-img v-if="localImageUrl" :src="localImageUrl" class="preview-image" />
</template>

<script>
export default {
  name: "",
  props: ["imageFile"],
  data: () => ({}),
  computed: {
    localImageUrl() {
      return this.getLocalImageFile(this.imageFile);
    },
  },
  methods: {
    getLocalImageFile(file) {
      if (!file) return null;
      return URL.createObjectURL(file);
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.preview-image {
  &::after {
    display: block;
    content: "x";
  }
}
</style>
